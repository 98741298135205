let routerModule = false;

let context = require.context('@/', true, /\/overrides\/client\/router\/index\.js/);
context.keys().forEach(key => {
  if ('./overrides/client/router/index.js' === key) {
    routerModule = context(key);
  }
});
if ( ! routerModule) {
  routerModule = require("@/client/router/router.js");
}

export default routerModule.default;
